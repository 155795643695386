import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack5';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
// material
import {
  Alert,
  Box,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import trendingDownFill from '@iconify/icons-eva/arrow-down-fill';
import API from '../../../services/API';
import { MIconButton } from '../../@material-extend';

// ----------------------------------------------------------------------

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function LoginForm({ setReferralCode }) {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isMobile, setisMobile] = useState(false);
  const [isOtpRecieved, setisOtpRecieved] = useState(false);
  const [showResend, setshowResend] = useState(false);
  const [showReferral, setshowReferral] = useState(false);
  let query = useQuery();
  const ref = query.get('ref');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address'),
    number: Yup.string()
      .min(10, 'Phone number is not valid')
      .max(10, 'Phone number is not valid')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Phone number is not valid'
      ),
    otp: Yup.string().min(6, 'OTP is not valid').max(6, 'OTP is not valid'),
    referral: Yup.string().min(7, 'Referral is not valid').max(7, 'Referral is not valid')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      otp: undefined,
      remember: true,
      referral: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm, setFieldError }) => {
      if (!values.email) {
        return setFieldError('email', 'Email is required')
      }
      if (!isOtpRecieved) {
        sendOtp(values, { setErrors, setSubmitting, resetForm });
      } else {
        handleLogin(values, { setErrors, setSubmitting, resetForm });
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    const _ref = query.get('ref');
    const _referal = localStorage.getItem('referral');
    if (_ref?.length === 7 && _ref !== "*******") {
      localStorage.setItem('referral', _ref);
      setFieldValue('referral', _ref);
      setshowReferral(true);
    } else if (_referal?.length && _referal !== "*******") {
      setFieldValue('referral', _referal);
      setshowReferral(true);
    }
  }, []);

  useEffect(() => {
    setReferralCode(values?.referral);
  }, [values?.referral]);

  const handleLogin = async (values, { setErrors, setSubmitting, resetForm }) => {
    try {
      if (!values.otp) return;
      await login(isMobile ? Number(values.number).toString() : values.email, Number(values.otp), values?.referral);
      enqueueSnackbar('Login success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      setisOtpRecieved(false);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSubmitting(false);
      setErrors({ afterSubmit: error.message });
    }
  };

  const sendOtp = async (values, { setErrors, setSubmitting, resetForm }) => {
    try {
      await API.getOTP(isMobile ? values.number : values.email);
      setisOtpRecieved(true);
      setshowResend(false);
      setTimeout(() => {
        setshowResend(true);
      }, [30 * 1000]);
      enqueueSnackbar('OTP send success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      if (isMountedRef.current) {
        setSubmitting(false);
      }
    } catch (error) {
      setSubmitting(false);
      setErrors({ afterSubmit: error?.message });
    }
  };

  const handleSendOtp = async () => {
    try {
      await API.getOTP(isMobile ? values.number : values.email);
      setisOtpRecieved(true);
      setshowResend(false);
      setTimeout(() => {
        setshowResend(true);
      }, [30 * 1000]);
      enqueueSnackbar('OTP send success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
          {isMobile ? (
            <TextField
              fullWidth
              type="number"
              label="Phone number"
              {...getFieldProps('number')}
              error={Boolean(touched.number && errors.number)}
              helperText={touched.number && errors.number}
            />
          ) : (
            <TextField
              fullWidth
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          )}
          {!showReferral && !isOtpRecieved &&
            <Typography fontSize={"14px"} sx={{ margin: "0 !important", marginTop: "10px !important", cursor: "pointer" }} onClick={() => setshowReferral(!showReferral)}>
              Referral Code (optional) <span style={{ position: "relative", top: "3px" }}><Icon width={16} height={16} icon={trendingDownFill} />    </span>
            </Typography>
          }

          {showReferral &&
            <Box sx={{ mt: 1, mb: 2 }}>
              <TextField
                fullWidth
                type={'text'}
                label="Referral Code"
                {...getFieldProps('referral')}
                error={Boolean(touched.referral && errors.referral)}
                helperText={touched.referral && errors.referral}
              />
            </Box>
          }

          {!isOtpRecieved && values.referral?.length === 7 && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              Note: Referral code only works with new account
            </Alert>
          )}

          {isOtpRecieved && (
            <TextField
              fullWidth
              type={'number'}
              label="OTP"
              {...getFieldProps('otp')}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <IconButton onClick={handleShowPassword} edge="end">
              //         <Icon icon={showPassword ? eyeFill : eyeOffFill} />
              //       </IconButton>
              //     </InputAdornment>
              //   )
              // }}
              error={Boolean(touched.otp && errors.otp)}
              helperText={touched.otp && errors.otp}
            />
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {isOtpRecieved && (
            <Typography
              sx={{ fontSize: '12px', cursor: 'pointer' }}
              onClick={() => {
                if (!isSubmitting && showResend) handleSendOtp();
              }}
            >
              Resend OTP {!showResend && '( Wait 30 Sec )'}
            </Typography>
          )}
        </Stack>

        {isOtpRecieved && (
          <Alert severity="warning" sx={{ mb: 3 }}>
            Kindly check your spam folder, also mark the email as Not spam.
          </Alert>
        )}

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {!isOtpRecieved ? 'Send OTP' : 'Login'}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
